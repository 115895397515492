@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media

.bs-w-footer
  padding: rem-fn(30)

  hr
    border: rem-fn(1) solid #eee
    margin-block-start: 0
    margin-block-end: rem-fn(30)
    margin-inline: rem-fn(-30) // footer padding

    @include media-query.from(media.$md-min)
      margin-inline: 0

  @include media-query.from(media.$md-min)
    inline-size: 100%
    margin-inline: auto
    max-inline-size: rem-fn(media.$content-max-width)
    padding-block: rem-fn(60)

    hr
      margin-block-end: rem-fn(30)

.bs-w-f-top
  align-items: center
  display: grid
  gap: rem-fn(30)
  grid-template-areas: 'logo social' 'menu menu'
  justify-content: space-between
  margin-block-end: rem-fn(30)

  .bs-w-logo
    grid-area: logo

  .bs-f-nav
    grid-area: menu

  .bs-w-f-social-links
    grid-area: social

  @include media-query.from(media.$md-min)
    column-gap: rem-fn(60)
    grid-template-areas: 'logo menu social'
    grid-template-columns: max-content 1fr max-content
    margin-block-end: rem-fn(40)

.bs-w-f-social-links
  align-items: center
  column-gap: rem-fn(20)
  display: flex

  a
    display: inline-flex

.bs-f-nav
  list-style: none
  margin: 0
  padding: 0

  li
    &:not(:last-child)
      margin-block-end: rem-fn(20)

      @include media-query.from(media.$md-min)
        margin-block-end: 0

    a.bs-f-n-highlight
      color: get-color(bs-g)
      font-weight: 700
      text-decoration: underline

  @include media-query.from(media.$md-min)
    display: flex
    column-gap: rem-fn(40)

.bs-f-bottom
  @include media-query.from(media.$md-min)
    display: flex
    justify-content: space-between

.bs-f-copyright
  margin-block-end: rem-fn(20)

  @include media-query.from(media.$md-min)
    margin-block-end: 0

.bs-f-b-nav
  display: flex
  flex-flow: row wrap
  gap: rem-fn(10) rem-fn(30)

  a, button
    font-size: rem-fn(12)
    font-weight: 700
    line-height: line-height(16, 12)

  button
    background-color: transparent
    border: 0
    cursor: pointer
    padding: 0

    &:hover
      text-decoration: underline
