@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as *
@use 'src/css/variables/border-radius' as *

.bs-w-dialog
  .q-dialog
    &__inner
      > div
        background-color: get-color(bs-w)
        border-radius: $border-radius-base
        margin-block-start: rem-fn(230)
        max-inline-size: rem-fn(920)
        padding-block: rem-fn(70)
        padding-inline: rem-fn(40)

        @include from($breakpoint-md-min)
          padding-inline: rem-fn(200)
          padding-block: rem-fn(90)

.bs-w-dialog-inner
  text-align: center
  position: relative

  h1
    margin-block-end: rem-fn(60)

.bs-w-close-dialog
  background: none
  border: none
  color: get-color(bs-g)
  cursor: pointer
  line-height: 1
  padding: 0
  position: absolute
  right: rem-fn(30)
  top: rem-fn(30)

.bs-w-btn-group
  display: flex
  flex-flow: row wrap
  gap: rem-fn(20)
  justify-content: center

  a
    align-items: center
    border: rem-fn(1) solid get-color(bs-lo)
    border-radius: $border-radius-base
    block-size: rem-fn(160)
    display: flex
    flex-flow: column
    inline-size: rem-fn(160)
    justify-content: space-between
    padding-block: rem-fn(42)

    svg
      color: get-color(bs-g)
